@font-face {
  font-family: "MisakiGothic2nd";
  src: url("/assets/misaki_gothic_2nd.eot?") format('eot'),
       url("/assets/misaki_gothic_2nd.woff2") format('woff2'),
       url("/assets/misaki_gothic_2nd.woff") format('woff');
}

body {
  font-family: "MisakiGothic2nd";
}

.tooltip {
  font-family: "MisakiGothic2nd" !important;
}
